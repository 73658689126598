// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CountriesJson from "../assets/countries.json";
import * as ControlPlaneRedirectModalSelectCountryScss from "./ControlPlaneRedirectModalSelectCountry.scss";

var css = ControlPlaneRedirectModalSelectCountryScss;

var countriesJson = CountriesJson;

function ControlPlaneRedirectModalSelectCountry(props) {
  var onChange = props.onChange;
  return JsxRuntime.jsxs("select", {
              children: [
                JsxRuntime.jsx("option", {
                      children: "Select country",
                      value: ""
                    }),
                Belt_Array.map(countriesJson, (function (country) {
                        return JsxRuntime.jsx("option", {
                                    children: country,
                                    value: country
                                  }, country);
                      }))
              ],
              className: css.select,
              onChange: (function ($$event) {
                  onChange($$event.target.value);
                })
            });
}

var make = ControlPlaneRedirectModalSelectCountry;

export {
  css ,
  countriesJson ,
  make ,
}
/* css Not a pure module */
