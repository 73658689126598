// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../../../../libs/Env.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ControlPlaneRedirectModal from "../redirect-modal/ControlPlaneRedirectModal.res.js";

function DeployNowButtonMulticloud(props) {
  var userData = props.userData;
  var isUserLoggedIn = props.isUserLoggedIn;
  var match = React.useState(function () {
        return false;
      });
  var setIsControlPlaneRedirectModalOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsLoginModalOpen = match$1[1];
  var redirectToControlPlane = function (redirectUri) {
    if (redirectUri !== undefined) {
      return Url.visit(redirectUri);
    } else if (Env.controlPlaneRedirectLoginUrl !== undefined) {
      return Url.visit(Env.controlPlaneRedirectLoginUrl);
    } else {
      return ;
    }
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Button.make, {
                      size: props.size,
                      color: "Primary",
                      expanded: props.expanded,
                      onClick: (function (_event) {
                          if (isUserLoggedIn && userData !== undefined) {
                            var match = userData.controlPlaneAccountStatus;
                            var exit = 0;
                            switch (match) {
                              case "Active" :
                                  return redirectToControlPlane(undefined);
                              case "InProgress" :
                              case "Inactive" :
                                  exit = 2;
                                  break;
                              
                            }
                            if (exit === 2) {
                              return setIsControlPlaneRedirectModalOpen(function (param) {
                                          return true;
                                        });
                            }
                            
                          }
                          setIsLoginModalOpen(function (param) {
                                return true;
                              });
                        }),
                      children: "Deploy Now"
                    }),
                userData !== undefined && match[0] ? JsxRuntime.jsx(ControlPlaneRedirectModal.make, {
                        onClose: (function (param) {
                            setIsControlPlaneRedirectModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        email: userData.email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        onSignup: redirectToControlPlane
                      }) : null,
                match$1[0] ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            setIsLoginModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        callback: (function () {
                            setIsLoginModalOpen(function (param) {
                                  return false;
                                });
                            setIsControlPlaneRedirectModalOpen(function (param) {
                                  return true;
                                });
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to start deploying your CloudRack™️ services today!"
                      }) : null
              ]
            });
}

var make = DeployNowButtonMulticloud;

export {
  make ,
}
/* Env Not a pure module */
