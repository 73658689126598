// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tooltip from "../../../../../../../styleguide/components/Tooltip/Tooltip.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function ControlPlaneRedirectModalReadOnlyTooltip(props) {
  return JsxRuntime.jsx(Tooltip.make, {
              content: JsxRuntime.jsx("div", {
                    children: "You can't edit this field because we use credentials from your Datacenters.com account. Update your account settings to change it."
                  }),
              position: "Bottom",
              hideWhen: props.hide,
              children: props.children
            });
}

var make = ControlPlaneRedirectModalReadOnlyTooltip;

export {
  make ,
}
/* Tooltip Not a pure module */
