// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RedirectModalFormCellScss from "./RedirectModalFormCell.scss";
import * as ControlPlaneRedirectModalReadOnlyTooltip from "../../../../bundles/services/multicloud/components/redirect-modal/components/tooltip/ControlPlaneRedirectModalReadOnlyTooltip.res.js";

var css = RedirectModalFormCellScss;

function RedirectModalFormCell(props) {
  var __enableReadOnlyPopup = props.enableReadOnlyPopup;
  var __hasError = props.hasError;
  var title = props.title;
  var hasError = __hasError !== undefined ? __hasError : false;
  var enableReadOnlyPopup = __enableReadOnlyPopup !== undefined ? __enableReadOnlyPopup : false;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                title,
                                ":"
                              ],
                              className: Cx.cx([
                                    css.formRowTitle,
                                    hasError ? css.formRowTitleError : ""
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(ControlPlaneRedirectModalReadOnlyTooltip.make, {
                                    children: props.children,
                                    hide: enableReadOnlyPopup === false
                                  }),
                              className: css.formRowValue
                            })
                      ],
                      className: css.formRow
                    }),
                JsxRuntime.jsx("div", {
                      children: hasError ? "The \"" + title + "\" field is mandatory" : "",
                      className: css.formRowErrorMessage
                    })
              ],
              className: css.wrapper
            });
}

var make = RedirectModalFormCell;

export {
  css ,
  make ,
}
/* css Not a pure module */
