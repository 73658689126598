// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RedirectModalTitleScss from "./RedirectModalTitle.scss";

var css = RedirectModalTitleScss;

function RedirectModalTitle(props) {
  return JsxRuntime.jsx(H2.make, {
              className: css.heading,
              children: props.children
            });
}

var make = RedirectModalTitle;

export {
  css ,
  make ,
}
/* css Not a pure module */
