// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UsaStatesJson from "../assets/usaStates.json";
import * as ControlPlaneRedirectModalSelectStateScss from "./ControlPlaneRedirectModalSelectState.scss";

var css = ControlPlaneRedirectModalSelectStateScss;

var usaStatesJson = UsaStatesJson;

function ControlPlaneRedirectModalSelectState(props) {
  var __isDisabled = props.isDisabled;
  var onChange = props.onChange;
  var isDisabled = __isDisabled !== undefined ? __isDisabled : false;
  return JsxRuntime.jsxs("select", {
              children: [
                JsxRuntime.jsx("option", {
                      children: "Select state",
                      value: ""
                    }),
                Belt_Array.map(usaStatesJson, (function (state) {
                        return JsxRuntime.jsx("option", {
                                    children: state,
                                    value: state
                                  }, state);
                      }))
              ],
              className: css.select,
              disabled: isDisabled,
              onChange: (function ($$event) {
                  onChange($$event.target.value);
                })
            });
}

var make = ControlPlaneRedirectModalSelectState;

export {
  css ,
  usaStatesJson ,
  make ,
}
/* css Not a pure module */
