// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Html from "../../../../../libs/Html.res.js";
import * as Tooltip from "../../../../../styleguide/components/Tooltip/Tooltip.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FreeMonthsExplainerScss from "./FreeMonthsExplainer.scss";

var css = FreeMonthsExplainerScss;

function FreeMonthsExplainer(props) {
  return JsxRuntime.jsx(Tooltip.make, {
              content: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      "Offer is limited to the following:",
                      JsxRuntime.jsxs("ul", {
                            children: [
                              JsxRuntime.jsx("li", {
                                    children: "3 Global Virtual Clouds (GVCs)"
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: "3 Cloud Locations"
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: "3 Workloads per GVC"
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: "5 Replicas per Org per Location"
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: "1 vCPU and 1 GB of RAM per Replica"
                                  })
                            ],
                            className: css.tooltipContentUl
                          })
                    ]
                  }),
              position: "Bottom",
              className: css.tooltipWrapper,
              tooltipContainerClass: css.tooltipInnerContainer,
              children: "2" + Html.nbsp + "months" + Html.nbsp + "FREE!"
            });
}

var make = FreeMonthsExplainer;

export {
  css ,
  make ,
}
/* css Not a pure module */
