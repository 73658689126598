// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../Link/Link.res.js";
import * as Button from "../Button/Button.res.js";
import * as Portal from "../Portal/Portal.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Page from "../../../routes/common/Routes_Page.res.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RedirectModalScss from "./RedirectModal.scss";
import LogoWhiteSvg from "Images/logo-white.svg";
import BaremetalHeaderLeftSvg from "./../../../bundles/services/bare-metal-cloud/common/assets/baremetal-header-left.svg";
import BaremetalHeaderRightSvg from "./../../../bundles/services/bare-metal-cloud/common/assets/baremetal-header-right.svg";

var css = RedirectModalScss;

var bareMetalHeaderLeftSvg = BaremetalHeaderLeftSvg;

var bareMetalHeaderRightSvg = BaremetalHeaderRightSvg;

function RedirectModal(props) {
  var link = props.link;
  var providerLogo = props.providerLogo;
  var subtitleComponent = props.subtitleComponent;
  var tmp;
  tmp = props.modalType === "BareMetalCloud" ? "createBareMetal" : "createMulticloud";
  return JsxRuntime.jsx(Portal.make, {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            className: css.backgroundImageLeft,
                            style: {
                              backgroundImage: "url(" + bareMetalHeaderLeftSvg + ")"
                            }
                          }),
                      JsxRuntime.jsx("div", {
                            className: css.backgroundImageRight,
                            style: {
                              backgroundImage: "url(" + bareMetalHeaderRightSvg + ")"
                            }
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("img", {
                                                          src: LogoWhiteSvg
                                                        }),
                                                    className: css.logo
                                                  }),
                                              JsxRuntime.jsx("span", {
                                                    className: css.logoSeparator
                                                  }),
                                              link !== undefined ? JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx(Link.make, {
                                                            href: link,
                                                            children: JsxRuntime.jsx("img", {
                                                                  src: providerLogo
                                                                })
                                                          }),
                                                      className: css.logo
                                                    }) : JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx("img", {
                                                            src: providerLogo
                                                          }),
                                                      className: css.logo
                                                    })
                                            ],
                                            className: css.logoRow
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: "MD",
                                            color: "Clear",
                                            className: css.closeButton,
                                            onClick: props.onCloseButtonClick,
                                            children: JsxRuntime.jsx(IconClose.make, {
                                                  size: "LG",
                                                  color: "White"
                                                })
                                          })
                                    ],
                                    className: css.modalHeader
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      props.titleComponent,
                                      subtitleComponent !== undefined ? JsxRuntime.jsx("div", {
                                              children: Caml_option.valFromOption(subtitleComponent),
                                              className: css.subtitle
                                            }) : null,
                                      props.hasError ? JsxRuntime.jsxs("div", {
                                              children: [
                                                "Something went wrong. Please, try again later. ",
                                                JsxRuntime.jsx(Link.make, {
                                                      href: Routes_Page.contact,
                                                      children: "Contact us"
                                                    }),
                                                " if you need help."
                                              ],
                                              className: css.errorMessage
                                            }) : null,
                                      props.formComponent,
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              "We use credentials from your Datacenters.com account. ",
                                              JsxRuntime.jsx(Link.make, {
                                                    href: Routes_User.Personal.Dashboard.edit,
                                                    children: "Need to Change?"
                                                  })
                                            ],
                                            className: css.credentialsNote
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: "LG",
                                            color: "Primary",
                                            buttonClassName: tmp,
                                            onClick: props.onCreateAccountButtonClick,
                                            children: "Create Account"
                                          })
                                    ],
                                    className: css.modalBody
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "© " + new Date().getFullYear().toString() + " Datacenters.com. All rights reserved. ",
                                      JsxRuntime.jsx(Link.make, {
                                            href: Routes_Page.contact,
                                            children: "Need Help?"
                                          })
                                    ],
                                    className: css.modalFooter
                                  })
                            ],
                            className: Cx.cx([
                                  "container",
                                  css.modalBodyWrapper
                                ])
                          })
                    ],
                    className: css.modalRoot
                  })
            });
}

var make = RedirectModal;

export {
  css ,
  bareMetalHeaderLeftSvg ,
  bareMetalHeaderRightSvg ,
  make ,
}
/* css Not a pure module */
