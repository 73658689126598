// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as RedirectModalFormScss from "./RedirectModalForm.scss";

var css = RedirectModalFormScss;

function RedirectModalForm(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.form
            });
}

var make = RedirectModalForm;

export {
  css ,
  make ,
}
/* css Not a pure module */
