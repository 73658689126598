// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../../api/Api.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Page from "../../../../../routes/common/Routes_Page.res.js";
import * as RedirectModal from "../../../../../styleguide/components/RedirectModal/RedirectModal.res.js";
import * as RedirectModalForm from "../../../../../styleguide/components/RedirectModal/form/RedirectModalForm.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RedirectModalTitle from "../../../../../styleguide/components/RedirectModal/RedirectModalTitle.res.js";
import * as FreeMonthsExplainer from "../free-months-explainer/FreeMonthsExplainer.res.js";
import * as RedirectModalFormCell from "../../../../../styleguide/components/RedirectModal/form/RedirectModalFormCell.res.js";
import ControlPlaneSvg from "../assets/control-plane.svg";
import * as ControlPlaneRedirectModalData from "./components/data/ControlPlaneRedirectModalData.res.js";
import * as ControlPlaneRedirectModalScss from "./ControlPlaneRedirectModal.scss";
import * as ControlPlaneRedirectModalSelectState from "./components/select-state/ControlPlaneRedirectModalSelectState.res.js";
import * as ControlPlaneRedirectModalSelectCountry from "./components/select-country/ControlPlaneRedirectModalSelectCountry.res.js";

var css = ControlPlaneRedirectModalScss;

var imageControlPlaneLogo = ControlPlaneSvg;

function reducer(param, action) {
  return action._0;
}

function ControlPlaneRedirectModal(props) {
  var onSignup = props.onSignup;
  var match = React.useState(function () {
        return false;
      });
  var setHasError = match[1];
  var match$1 = React.useReducer(reducer, ControlPlaneRedirectModalData.Input.empty());
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setCreateAccountClicked = match$2[1];
  var createAccountClicked = match$2[0];
  var checkHasError = function (fieldValue) {
    if (!createAccountClicked) {
      return false;
    }
    var match = ControlPlaneRedirectModalData.Validate.checkNotEmpty(fieldValue);
    if (match.TAG === "Ok") {
      return false;
    } else {
      return true;
    }
  };
  return JsxRuntime.jsx(RedirectModal.make, {
              onCloseButtonClick: props.onClose,
              onCreateAccountButtonClick: (function (param) {
                  setCreateAccountClicked(function (param) {
                        return true;
                      });
                  if (ControlPlaneRedirectModalData.Validate.valid(ControlPlaneRedirectModalData.Validate.all(state))) {
                    return $$Promise.wait(Api.controlPlaneRegistrationRequest(state), (function (response) {
                                  if (response.TAG !== "Ok") {
                                    return setHasError(function (param) {
                                                return true;
                                              });
                                  }
                                  var response$1 = response._0;
                                  var match = response$1.message;
                                  if (match === "Success") {
                                    return onSignup(response$1.redirectUri);
                                  } else {
                                    return setHasError(function (param) {
                                                return true;
                                              });
                                  }
                                }));
                  }
                  
                }),
              titleComponent: JsxRuntime.jsxs(RedirectModalTitle.make, {
                    children: [
                      "Create Your ",
                      JsxRuntime.jsx(Link.make, {
                            href: Routes_Page.controlPlaneSignUp,
                            children: "Control Plane"
                          }),
                      " Multicloud Account via Datacenters.com and",
                      JsxRuntime.jsxs("span", {
                            children: [
                              "Get ",
                              JsxRuntime.jsx(FreeMonthsExplainer.make, {}),
                              "."
                            ]
                          })
                    ]
                  }),
              subtitleComponent: Caml_option.some(JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          "Please complete the form below to create your exclusive 2 month free account. Only from ",
                          JsxRuntime.jsx(Link.make, {
                                href: "/",
                                children: "Datacenters.com"
                              }),
                          "!"
                        ]
                      })),
              formComponent: JsxRuntime.jsxs(RedirectModalForm.make, {
                    children: [
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: props.email,
                            title: "Email",
                            enableReadOnlyPopup: true
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(RedirectModalFormCell.make, {
                                    children: props.firstName,
                                    title: "First Name",
                                    enableReadOnlyPopup: true
                                  }),
                              JsxRuntime.jsx(RedirectModalFormCell.make, {
                                    children: props.lastName,
                                    title: "Last Name",
                                    enableReadOnlyPopup: true
                                  })
                            ],
                            className: css.formRowMultiple
                          }),
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: JsxRuntime.jsx("input", {
                                  className: css.formInput,
                                  placeholder: "Add company name",
                                  onChange: (function ($$event) {
                                      dispatch({
                                            TAG: "Update",
                                            _0: {
                                              companyName: $$event.target.value,
                                              addressLine1: state.addressLine1,
                                              addressLine2: state.addressLine2,
                                              country: state.country,
                                              state: state.state,
                                              city: state.city,
                                              zip: state.zip
                                            }
                                          });
                                    })
                                }),
                            title: "Company",
                            hasError: checkHasError(state.companyName)
                          }),
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: JsxRuntime.jsx("input", {
                                  className: css.formInput,
                                  placeholder: "Add address line 1",
                                  onChange: (function ($$event) {
                                      dispatch({
                                            TAG: "Update",
                                            _0: {
                                              companyName: state.companyName,
                                              addressLine1: $$event.target.value,
                                              addressLine2: state.addressLine2,
                                              country: state.country,
                                              state: state.state,
                                              city: state.city,
                                              zip: state.zip
                                            }
                                          });
                                    })
                                }),
                            title: "Address Line 1",
                            hasError: checkHasError(state.addressLine1)
                          }),
                      JsxRuntime.jsx(RedirectModalFormCell.make, {
                            children: JsxRuntime.jsx("input", {
                                  className: css.formInput,
                                  placeholder: "Add address line 2 (optional)",
                                  onChange: (function ($$event) {
                                      dispatch({
                                            TAG: "Update",
                                            _0: {
                                              companyName: state.companyName,
                                              addressLine1: state.addressLine1,
                                              addressLine2: $$event.target.value,
                                              country: state.country,
                                              state: state.state,
                                              city: state.city,
                                              zip: state.zip
                                            }
                                          });
                                    })
                                }),
                            title: "Address Line 2"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(RedirectModalFormCell.make, {
                                    children: JsxRuntime.jsx(ControlPlaneRedirectModalSelectCountry.make, {
                                          onChange: (function (country) {
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      companyName: state.companyName,
                                                      addressLine1: state.addressLine1,
                                                      addressLine2: state.addressLine2,
                                                      country: country,
                                                      state: state.state,
                                                      city: state.city,
                                                      zip: state.zip
                                                    }
                                                  });
                                            })
                                        }),
                                    title: "Country",
                                    hasError: checkHasError(state.country)
                                  }),
                              JsxRuntime.jsx(RedirectModalFormCell.make, {
                                    children: JsxRuntime.jsx(ControlPlaneRedirectModalSelectState.make, {
                                          onChange: (function (usaState) {
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      companyName: state.companyName,
                                                      addressLine1: state.addressLine1,
                                                      addressLine2: state.addressLine2,
                                                      country: state.country,
                                                      state: usaState,
                                                      city: state.city,
                                                      zip: state.zip
                                                    }
                                                  });
                                            }),
                                          isDisabled: state.country !== "United States"
                                        }),
                                    title: "State"
                                  })
                            ],
                            className: css.formRowMultiple
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(RedirectModalFormCell.make, {
                                    children: JsxRuntime.jsx("input", {
                                          className: css.formInput,
                                          placeholder: "Add city",
                                          onChange: (function ($$event) {
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      companyName: state.companyName,
                                                      addressLine1: state.addressLine1,
                                                      addressLine2: state.addressLine2,
                                                      country: state.country,
                                                      state: state.state,
                                                      city: $$event.target.value,
                                                      zip: state.zip
                                                    }
                                                  });
                                            })
                                        }),
                                    title: "City",
                                    hasError: checkHasError(state.city)
                                  }),
                              JsxRuntime.jsx(RedirectModalFormCell.make, {
                                    children: JsxRuntime.jsx("input", {
                                          className: css.formInput,
                                          placeholder: "Add zip",
                                          onChange: (function ($$event) {
                                              dispatch({
                                                    TAG: "Update",
                                                    _0: {
                                                      companyName: state.companyName,
                                                      addressLine1: state.addressLine1,
                                                      addressLine2: state.addressLine2,
                                                      country: state.country,
                                                      state: state.state,
                                                      city: state.city,
                                                      zip: $$event.target.value
                                                    }
                                                  });
                                            })
                                        }),
                                    title: "Zip",
                                    hasError: checkHasError(state.zip)
                                  })
                            ],
                            className: css.formRowMultiple
                          })
                    ]
                  }),
              providerLogo: imageControlPlaneLogo,
              hasError: match[0],
              modalType: "Multicloud",
              link: Routes_Page.controlPlaneSignUp
            });
}

var make = ControlPlaneRedirectModal;

export {
  css ,
  imageControlPlaneLogo ,
  reducer ,
  make ,
}
/* css Not a pure module */
